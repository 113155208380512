<template>
	<v-row justify="center">
		<v-dialog :value="dialog" max-width="750" persistent @input="closeDialog">
			<v-card class="dialog-card">
				<v-card-title class="headline accent--text pb-4">Создание расписания из шаблона</v-card-title>

        <v-card-text outlined tile class="border-top">
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-container>
                <v-row>
                  <v-col cols="12" class="pb-0 px-0">
                    <v-alert type="warning" outlined class="body-2 mt-3 mb-5">
                      Убедитесь, что календарь выходных дней заполнен верно!
                    </v-alert>

                    <div class="templates-info accent--text">
                      <p>Выберите шаблон расписания из списка:</p>
                    </div>

                    <div class="table-container">
                      <v-data-table
                        ref="templatesTable"
                        class="base-table mt-2"
                        single-select
                        fixed-header
                        :loading="pairTimesLoading"
                        hide-default-footer
                        :headers="headers"
                        :items="templates"
                        :items-per-page="-1"
                      >
                        <template #group="{ items }">
                          {{ items }}
                        </template>

                        <template #item="{ item, index }">
                          <tr @click="rowClick(item)" :class="{ selectedRow: item === selectedItem }" :key="index">
                            <td>{{ item.description }}</td>
                            <td class="text-center">{{ item.linesCount }}</td>
                          </tr>
                        </template>
                      </v-data-table>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>

            <v-tab-item>
              <v-container>
                <v-row class="generate-params">
                  <v-col cols="12" class="px-0">
                    <v-alert type="info" outlined class="body-2 mt-3 mb-5">
                      Выберите параметры для генерации расписания из шаблона
                    </v-alert>

                    <div class="generate-info accent--text">
                      <p :title="selectedItemDescription">{{ selectedItemDescription }}</p>
                    </div>
                  </v-col>

                  <v-col cols="12" class="px-0 pt-0">
                    <v-autocomplete
                      v-model="beginTime"
                      label="Время начала занятий"
                      placeholder="Выберите из списка"
                      no-data-text="Нет значений для выбора"
                      hide-details
                      :items="pairTimes"
                      item-text="beginTime"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>

        <v-card-actions class="px-6">
          <v-spacer></v-spacer>
          <div v-show="tab === 0">
            <v-btn text color="accent" @click="closeDialog">Закрыть</v-btn>
          </div>

          <div v-show="tab === 1">
            <v-btn text color="accent" @click="backToList">Назад</v-btn>
            <v-btn text color="success" :loading="loading" :disabled="$v.$invalid" @click="generateSchedule">
              Создать расписание
            </v-btn>
          </div>

        </v-card-actions>
      </v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { catalogApi, scheduleApi } from '@/api'
export default {
	props: {
		dialog: Boolean,
    templates: Array,
    generateParams: Object
	},

  data: () => ({
    tab: 0,
    selectedItem: null,
    loading: false,
    pairTimes: [],
    pairTimesLoading: false,
    headers: [
      { text: 'Описание шаблона', sortable: false, align: 'left' },
      { text: 'Количество строк', sortable: false, align: 'center' }
    ],

    beginTime: null,
	}),

  validations() {
    return {
      beginTime: { required },
    }
  },

	computed: {
    ...mapGetters('department', ['departmentId']),
    ...mapGetters('cycle', ['cycleId']),

    selectedItemDescription() {
      return this.selectedItem?.description
    }
	},

	methods: {
    async rowClick(item) {
      try {
        this.pairTimesLoading = true
        this.pairTimes = await catalogApi.getAvailablePairTimeForScheduleGeneration(item?.scheduleTemplateId)
        this.selectedItem = item
        this.tab = 1
      } catch (error) {
        console.log(error)
        this.showErrorMessage(error, 'Не удалось получить расписание занятий')
      } finally {
        this.pairTimesLoading = false
      }
    },

    async generateSchedule() {
      try {
        this.loading = true

        const body = {
          beginTime: this.beginTime,
        }

        const data = await scheduleApi.generateSchedule(this.selectedItem?.scheduleTemplateId, this.cycleId, body)
        this.$emit('generateSchedule', data)
        this.closeDialog()
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка генерации расписания из шаблона')
      } finally {
        this.loading = false
      }
    },

    backToList() {
      this.selectedItem = null
      this.tab = 0
      this.resetGenerateParams()
    },

    resetGenerateParams() {
      this.beginTime = null
      this.pairTimes = []
    },

    closeDialog() {
      this.resetGenerateParams()
			this.$emit('closeDialog')
		}
	}
}
</script>

<style scoped>
.table-container {
  min-height: 300px;
}

.base-table {
  max-height: 300px;
  border: thin solid var(--separator-color) !important;
}

.base-table >>> tbody tr:hover td {
  background-color: var(--selectedRow-color) !important;
  cursor: pointer;
}

.templates-info p {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 12px;
  line-height: 1;
}

.generate-info {
  min-height: 38px;
  max-height: 38px;
  overflow: hidden;
}

.generate-info p {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 0;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
</style>