<template>
  <div>
    <v-row class="my-0">
      <v-col cols="12" class="py-0">
        <v-card class="base-card pa-5" ref="scheduleInfo">
          <v-btn
            class="widget-block-toggler px-0"
            text
            :ripple="false"
            :title="showInfo ? 'Скрыть виджет' : 'Показать виджет'"
            @click="setShowInfo"
          >
            <v-icon class="icon">{{ showInfo ? 'mdi-chevron-double-up' : 'mdi-chevron-double-down' }}</v-icon>
          </v-btn>

          <v-row class="my-0">
            <v-col :cols="showInfo ? 6 : 12" class="py-0">
              <div class="schedule-info accent--text mb-4" v-if="showInfo">
                <p><strong>Кол-во групп (план Аксапта): </strong>{{ cycleInfo.groupCnt }}</p>
                <p><strong>Кол-во групп (факт): </strong>{{ cycleInfo.groupCntFact }}</p>
              </div>

              <div :class="{'schedule-info-mini': !showInfo}">
                <div class="d-flex">
                  <span
                    :title="saveBtnTitle"
                    :class="{ 'cursor-help': disabledSaveBtn }"
                  >
                    <v-btn
                      :loading="saveScheduleLoading"
                      :disabled="disabledSaveBtn"
                      small
                      depressed
                      color="success"
                      class="btn white--text pl-3 pr-2 mr-2"
                      @click="$emit('sendScheduleLines')"
                    >
                      <v-icon left class="mr-0">mdi-content-save-outline</v-icon>
                    </v-btn>
                  </span>

                  <span
                    :title="removeBtnTitle"
                    :class="{ 'cursor-help': disabledRemoveBtn }"
                  >
                    <v-btn
                      :disabled="disabledRemoveBtn"
                      small
                      depressed
                      color="error"
                      class="btn white--text pl-3 pr-2 mr-5"
                      @click="$emit('removeScheduleLines')"
                    >
                      <v-icon left class="mr-0">mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </span>

                  <ReportMenu :disabled="!hasFactSchedule" />
                </div>

                <div>
                  <ul class="custom-tabs" :class="{'mini': !showInfo}" v-if="hasFact || (!hasFact && isAnyChanges.fact)">
                    <li :class="{'active': !isFact}" @click="setTypeTab(false)">Плановое расписание</li>
                    <li></li>
                    <li :class="{'active': isFact}" @click="setTypeTab(true)">Фактическое расписание</li>
                  </ul>

                  <div class="no-fact accent--text" :class="{'mini': !showInfo}" v-else>
                    <p><strong>Плановое расписание</strong></p>

                    <span
                      :title="createFactScheduleBtnTitle"
                      :class="{ 'cursor-help': disabledCreateFactScheduleBtn }"
                    >
                      <v-btn
                        :disabled="disabledCreateFactScheduleBtn"
                        small
                        color="primary"
                        class="white--text px-4"
                        @click="$emit('createFactSchedule')"
                      >
                        <v-icon left>mdi-text-box-plus-outline</v-icon>Создать фактическое расписание
                      </v-btn>
                    </span>
                  </div>
                </div>
              </div>
            </v-col>

            <v-col cols="6" class="py-0" v-if="showInfo">
              <div class="schedule-info accent--text">
                <p><strong>Распределение часов</strong></p>

                <SheduleWidget
                  v-if="widgetByType.length"
                  :widget="widgetByType"
                  :isCorrectWidgetSum="isCorrectWidgetSumByType"
                />

                <span v-else class="no-widget-data">
                  Нет данных для расчета, распределите слушателей на группы
                </span>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ReportMenu from '@/components/cycles/cycle/schedule/ReportMenu'
import SheduleWidget from '@/components/cycles/cycle/schedule/Widget'

export default  {
  name: 'ScheduleInfo',

  props: {
    isFact: Boolean,
    hasFact: Boolean,
    hasPlanSchedule: Boolean,
    hasFactSchedule: Boolean,
    isAllowSaveFactSchedule: Boolean,
    isAnyChanges: Object,
    saveScheduleLoading: Boolean,
    widget: Object,
    widgetErrors: Object
  },

  components: {
    ReportMenu,
    SheduleWidget
  },

  mounted() {
    this.setScheduleInfoHeight()
    window.addEventListener("resize", this.setScheduleInfoHeight)
  },

  destroyed() {
    window.removeEventListener("resize", this.setScheduleInfoHeight)
  },

  data: () => ({
    showInfo: true
  }),

  computed: {
    ...mapGetters('cycle', ['cycleInfo', 'isCycleComplete', 'currentDateMoreCycleFromDate', 'groupCntFact']),

    isAnyChangesByType() {
      return this.isFact ? this.isAnyChanges.fact : this.isAnyChanges.plan
    },

    widgetByType() {
      return this.isFact ? this.widget.fact.academicWorks : this.widget.plan.academicWorks
    },

    widgetErrorsByType() {
      return this.isFact ? this.widgetErrors.fact : this.widgetErrors.plan
    },

    isCorrectWidgetSumByType() {
      return this.isFact ? this.widget.fact.isCorrect : this.widget.plan.isCorrect
    },

    disabledCreateFactScheduleBtn() {
      return !this.groupCntFact || this.isAnyChanges.plan || !this.hasPlanSchedule
    },

    createFactScheduleBtnTitle() {
      if (this.isAnyChanges.plan) return 'Сохраните плановое расписание'
      else if (!this.groupCntFact) return 'Добавьте слушателей и распределите их на группы'
      else if (!this.hasPlanSchedule) return 'Заполните поле "Вид занятия" для всех строк расписания'
      else return 'Создать фактическое расписание'
    },

    disabledSaveBtn() {
      return this.isFact
        ? !this.isAnyChangesByType || !this.isAllowSaveFactSchedule
        : !this.isAnyChangesByType
    },

    disabledRemoveBtn() {
      return this.isFact
        ? this.isCycleComplete || !this.disabledSaveBtn
        : this.isCycleComplete || !this.disabledSaveBtn || this.hasFact
    },

    saveBtnTitle() {
      if (!this.isAnyChangesByType) return 'Нет ни одного изменения'
      else if (!this.isAllowSaveFactSchedule) return 'Выберите преподавателя и группу для каждой строки расписания'

      return this.isFact ? 'Сохранить фактическое расписание' : 'Сохранить плановое расписание'
    },

    removeBtnTitle() {
      if (this.isCycleComplete) return 'Цикл завершён. Нельзя удалить расписание.'
      if (!this.isFact && this.hasFact) return 'Сначала удалите фактическое расписание'
      if (!this.isFact && !this.hasFact && this.isAnyChanges.fact) return 'Сначала сохраните фактическое расписание'

      return this.isFact ? 'Удалить фактическое расписание' : 'Удалить плановое расписание'
    }
  },

  methods: {
    setShowInfo() {
      this.showInfo = !this.showInfo
      this.setScheduleInfoHeight()
    },

    setTypeTab(flag) {
      this.$emit('setTypeTab', flag)
      this.setScheduleInfoHeight()
    },

    setScheduleInfoHeight() {
      this.$nextTick(() => {
        const scheduleInfo = this.$refs.scheduleInfo

        if (scheduleInfo) {
          this.$emit('setScheduleInfoHeight', scheduleInfo.$el.offsetHeight)
        }
      })
    }
  }
}
</script>

<style scoped>
.base-card .widget-block-toggler {
  position: absolute;
  top: -3px;
  right: -17px;
}

.base-card .widget-block-toggler::before {
  background-color: transparent !important;
}

.base-card .widget-block-toggler .icon {
  font-size: 20px;
  color: #a6b6be !important;
}

.schedule-info p {
  font-size: 16px;
  margin-bottom: 6px;
  line-height: 1;
}

.schedule-info p:last-child {
  margin-bottom: 0;
}

.schedule-info-mini {
  display: flex;
  justify-content: space-between;
}

.custom-tabs {
  padding-left: 0;
  margin-top: 16px;
}

.custom-tabs.mini {
  margin-top: 0;
  margin-right: 12px;
}

.custom-tabs li {
  list-style-type: none;
  display: inline;
  font-size: 15px;
  font-weight: 500;
  color: var(--accent-color);
  cursor: pointer;
}

.custom-tabs li:nth-child(2) {
  margin-left: 8px;
  margin-right: 8px;
  border-right: 1px solid var(--accent-color);
  cursor: default;
}

.custom-tabs li.active {
  border-bottom: 2px solid var(--primary-color);
  padding-bottom: 6px;
}

.no-fact {
  margin-top: 16px;
}

.no-fact.mini {
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-right: 12px;
}

.no-fact.mini p {
  margin-bottom: 0;
  margin-right: 16px;
}

.no-fact p {
  font-size: 16px;
  line-height: 1;
  margin-bottom: 8px;
}

.no-widget-data {
  display: block;
  font-size: 14px;
  color: var(--accent-color);
  margin-top: 12px;
}

.cursor-help {
  cursor: help;
}

.btn.v-size--small {
  min-width: auto;
}
</style>