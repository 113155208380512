<template>
  <div>
    <v-row class="my-0">
      <v-col
        cols="12"
        class="py-0"
      >
        <v-card class="base-card pa-5">
          <div class="schedule-info accent--text">
            <p><strong>Кол-во групп (план Аксапта): </strong>{{ cycleInfo.groupCnt }}</p>
            <p><strong>Кол-во групп (факт): </strong>{{ cycleInfo.groupCntFact }}</p>
          </div>

          <div class="d-flex mt-5">
            <v-btn
              :loading="templatesLoading"
              small
              color="primary"
              class="white--text px-4 mr-3"
              @click="getTemplatesParams"
            >
              <v-icon left>mdi-clipboard-text-outline</v-icon>
              Создать расписание из шаблона
            </v-btn>

            <v-btn
              :loading="createLoading"
              small
              color="primary"
              class="white--text px-4 mr-5"
              @click="getCreateParams"
            >
              <v-icon left>mdi-plus</v-icon>
              Создать новое расписание
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <ScheduleTemplatesDialog
      :dialog="scheduleTemplatesDialog"
      :templates="templates"
      @generateSchedule="$emit('generateSchedule', $event)"
      @closeDialog="closeScheduleTemplatesDialog"
    />

    <ScheduleCreateDialog
      :dialog="scheduleCreateDialog"
      :createParams="createParams"
      @createNewSchedule="$emit('createNewSchedule', $event)"
      @closeDialog="closeScheduleCreateDialog"
    />
  </div>
</template>

<script>
import { catalogApi, scheduleApi } from '@/api'
import { mapGetters } from 'vuex'
import ScheduleTemplatesDialog from '@/components/cycles/cycle/schedule/dialogs/ScheduleTemplates'
import ScheduleCreateDialog from '@/components/cycles/cycle/schedule/dialogs/ScheduleCreate'

export default {
  name: 'ScheduleCreate',

  components: {
    ScheduleTemplatesDialog,
    ScheduleCreateDialog
  },

  data: () => ({
    dataLoaded: false,
    scheduleTemplatesDialog: false,
    scheduleCreateDialog: false,
    templatesLoading: false,
    createLoading: false,
    templates: [],

    createParams: {
      pairTimes: []
    }
  }),

  computed: {
    ...mapGetters('department', ['departmentId']),
    ...mapGetters('cycle', ['cycleInfo'])
  },

  methods: {
    sortByName(a, b) {
      const A = a.description.toLowerCase(), B = b.description.toLowerCase()
      return A < B ? -1 : (A > B ? 1 : 0)
    },

    async getTemplatesParams() {
      try {
        this.templatesLoading = true

        this.templates = await scheduleApi.getScheduleTemplatesList(this.departmentId)
        this.templates.sort(this.sortByName)

        if (!this.templates.length) {
          this.$toastr('warning', '', 'Нет шаблонов для выбора')
          return
        }

        this.templatesLoading = false
        this.scheduleTemplatesDialog = true
      } catch (e) {
        console.log(e.status)
      }
    },

    async getCreateParams() {
      try {
        this.createLoading = true

        if (!this.createParams.pairTimes.length) {
          const pairTimes = await catalogApi.getPairTime()
          this.createParams.pairTimes = pairTimes
        }

        if (!this.createParams.pairTimes.length) {
          this.$toastr('warning', '', 'Не удалось получить расписание пар')
          this.createLoading = false
          return
        }

        this.createLoading = false
        this.scheduleCreateDialog = true
      } catch (e) {
        console.log(e.status)
      }
    },

    closeScheduleTemplatesDialog() {
      this.scheduleTemplatesDialog = false
    },

    closeScheduleCreateDialog() {
      this.scheduleCreateDialog = false
    }
  }
}
</script>

<style scoped>
.schedule-info p {
  font-size: 15px;
  margin-bottom: 6px;
  line-height: 1;
}

.schedule-info p:last-child {
  margin-bottom: 0;
}
</style>