<template>
  <div>
    <v-row class="my-0">
      <v-col cols="12" class="py-0">
        <v-card class="table-card mt-4">
          <v-data-table
            ref="scheduleTable"
            class="base-table"
            fixed-header
            hide-default-footer
            group-by="position"
            :headers="headersComputed"
            :items="linesComputed"
            :items-per-page="-1"
          >
            <!-- custom table header columns -->
            <template #header.hours="{ header }">
              <span v-html="header.text"></span>
            </template>

            <template #header.academicWork.shortName="{ header }">
              <span v-html="header.text"></span>
            </template>

            <template #header.groupCount="{ header }">
              <span v-html="header.text"></span>
            </template>

            <template #header.actions="{ header }">
              <div>Действия</div>

              <v-icon
                v-if="isAllowEdit"
                color="btnIcon"
                class="add-btn"
                size="22"
                title="Добавить строку"
                @click="openAddLine"
              >
                mdi-plus
              </v-icon>
            </template>

            <template #group.header="{ group, items, isOpen, toggle }">
              <th colspan="9" class="px-0">
                <div
                  class="custom-th date"
                  :title="isOpen ? 'Скрыть строки' : 'Показать строки'"
                  @click="toggle"
                >
                  <div>
                    <span>{{ items[0].date | formatDateReverseFromISO }}</span>

                    <v-icon size="20" color="accent">
                      {{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                    </v-icon>
                  </div>

                  <div :class="isAllowEdit ? 'total-date-hours' : 'total-date-hours-without-actions'">
                    {{ getDateTotalHours(items[0].date) }}
                  </div>
                </div>
              </th>
            </template>


            <template #item.groups="{ item }">
              <span>{{ item.groups | showGroupsAsRow }}</span>
            </template>

            <template #item.groupCount="{ item }">
              <span>{{ item.groups.length || null }}</span>
            </template>

            <template #item.name="{ item }">
              <span>{{ item.chapter ? `${item.chapter?.name}. ${item.name}` : item.name }}</span>
            </template>

            <template #item.scheduleLects="{ item }">
              <span v-for="(lect, i) in item.scheduleLects" :key="i">{{ lect.shortName }}<br></span>
            </template>

            <template #item.actions="{ item, index }">
              <v-icon color="lightGrey" class="mr-1 copy-btn" size="22" title="Копировать" @click="openCopyLine(item, index)">
                mdi-content-copy
              </v-icon>
              <v-icon color="lightGrey" class="mr-1 edit-btn" size="22" title="Редактировать" @click="openEditLine(item, index)">
                mdi-pencil-outline
              </v-icon>
              <v-icon color="lightGrey" class="remove-btn" size="22" title="Удалить" @click="deleteLine(item, index)">
                mdi-trash-can-outline
              </v-icon>
            </template>

            <template slot="no-data">
              <div>Таблица пуста</div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <ScheduleLineDialog
      :dialog="dialog"
      :isEdit="isEdit"
      :chaptersList="chaptersList"
      :cycleHolidays="cycleHolidays"
      :scheduleLine="selectedItem"
      @addLine="addLine"
      @editLine="editLine"
      @closeDialog="closeDialog"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatDateReverseFromISO } from '@/scripts'
import { ScheduleLine } from '@/models'
import ScheduleLineDialog from '@/components/cycles/cycle/schedule/dialogs/ScheduleLine'

export default {
  name: 'ScheduleTable',

  props: {
    lines: Array,
    cycleHolidays: Object,
    isFact: Boolean,
    hasFact: Boolean,
    hasFactSchedule: Boolean,
    scheduleInfoHeight: Number,
    infoBlockHeight: Number
  },

  components: {
    ScheduleLineDialog
  },

  mounted() {
    this.setTableHeight()
    window.addEventListener("resize", this.setTableHeight)
  },

  destroyed() {
    window.removeEventListener("resize", this.setTableHeight)
  },

  data: () => ({
    dataLoaded: false,
    headers: [
      { text: 'Время', value: 'startEndTimes', sortable: false, align: 'left', width: '100px' },
      { text: 'Вид<br>занятия', value: 'academicWork.shortName', sortable: false, align: 'center' },
      { text: '№ гр.', value: 'groups', sortable: false, align: 'center' },
      { text: 'Кол-во<br>групп', value: 'groupCount', sortable: false, align: 'center' },
      { text: 'Раздел. Тема', value: 'name', sortable: false, align: 'left' },
      { text: 'Место', value: 'eventPlace.fullAddress', sortable: false, align: 'left' },
      { text: 'Преподаватель', value: 'scheduleLects', sortable: false, align: 'center' },
      { text: 'Кол-во<br>часов', value: 'hours', sortable: false, align: 'center', width: '80px' },
      { text: 'Действия', value: 'actions', sortable: false, align: 'right', width: '90px' }
    ],
    selectedItem: null,
    selectedItemDateIndex: null,
    isCopyMode: false,
    dialog: false,
    confirmDialog: false,
    themeLoading: false,
    deleteLoading: false
  }),

  computed: {
    ...mapGetters('system', ['techMessageHeight']),
    ...mapGetters('cycle', ['cycleId', 'isCycleComplete', 'currentDateMoreCycleFromDate']),

    linesComputed() {
      // т.к. группировка таблицы по дате сортирует некорректно (как строки),
      // то группируем по свойству position, которое равно позиции даты в списке дат
      this.lines.forEach(line => line.position = this.datesList.indexOf(line.date))

      return this.lines
    },

    isEdit() {
      return this.selectedItemDateIndex !== null
    },

    isAllowEdit() {
      return this.isFact
        ? true
        : !this.hasFact || [69337, 68001].includes(this.cycleId)
    },

    headersComputed() {
      return this.isAllowEdit ? this.headers : this.headers.slice(0, -1)
    },

    isLinesWithId() {
      return this.lines.some(line => line.scheduleId)
    },

    chaptersList() {
      const list = new Set()
      this.lines.forEach(line => line.chapter && list.add(line.chapter))

      return [...list]
    },

    datesList() {
      const list = new Set()
      this.lines.forEach(line => line.date && list.add(line.date))

      return [...list]
    }
  },

  filters: {
    showGroupsAsRow(val) {
      return val.length ? val.join(', ') : null
    },

    formatDateReverseFromISO
  },

  methods: {
    getDateTotalHours(date) {
      const dateLines = this.lines.filter(el => el.date === date)
      return dateLines.reduce((acc, cur) => acc + +cur.hours, 0)
    },

    setTableHeight() {
      const otherElements = 240 + this.techMessageHeight

      if (this.$refs?.scheduleTable) {
        this.$refs.scheduleTable.$el.style.maxHeight =
          window.innerHeight - this.scheduleInfoHeight - this.infoBlockHeight - otherElements + 'px'
      }
    },

    openAddLine(e) {
      e.stopPropagation()
      this.selectedItem = new ScheduleLine()
      this.selectedItem.isFact = this.isFact
      this.dialog = true
    },

    openEditLine(item, index) {
      this.selectedItem = item
      this.selectedItemDateIndex = index
      this.dialog = true
    },

    openCopyLine(item, index) {
      this.isCopyMode = true
      this.selectedItem = item
      this.selectedItemDateIndex = index
      this.dialog = true
    },

    addLine(item) {
      this.$emit('addLine', item)
    },

    editLine(params) {
      const emitName = this.isCopyMode ? 'copyLine' : 'editLine'
      const { date, line } = params

      this.$emit(emitName, {
        line,
        date,
        index: this.selectedItemDateIndex,
        isFact: this.isFact
      })
    },

    deleteLine(item, index) {
      this.$emit('deleteLine', {
        date: item.date,
        index,
        isFact: this.isFact
      })
    },

    closeDialog() {
      this.dialog = false
      this.isCopyMode = false
      this.selectedItem = null
      this.selectedItemDateIndex = null
    }
  },

  watch: {
    scheduleInfoHeight() {
      this.setTableHeight()
    },

    infoBlockHeight() {
      this.setTableHeight()
    }
  }
}
</script>

<style scoped>
.base-table {
  border-radius: 10px 10px 0 0 !important;
}

.base-table >>> .v-data-table-header th {
  padding-left: 0 !important;
  padding-right: 20px !important;
}

.base-table >>> .v-data-table-header th:first-child {
  padding-left: 20px !important;
}

.base-table >>> .add-btn {
  margin-top: 2px;
}

.base-table >>> .add-btn, .remove-btn {
  margin-right: -4px;
}

.base-table >>> td, th, .custom-th {
  height: 32px !important;
}

.base-table >>> td {
  padding-left: 0 !important;
  padding-right: 20px !important;
}

.base-table >>> td:first-child {
  padding-left: 20px !important;
}

.base-table >>> .custom-th {
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
}

.base-table >>> .custom-th.date {
  justify-content: space-between;
  background-color: #F0F0F0 !important;
  cursor: pointer;
}

.base-table >>> .total-date-hours {
  width: 40px;
  margin-right: 100px;
  text-align: center;
}

.base-table >>> .total-date-hours-without-actions {
  width: 40px;
  margin-right: 10px;
  text-align: center;
}
</style>