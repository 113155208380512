<template>
  <div>
    <v-data-table
      ref="scheduleWidget"
      class="base-table mt-1"
      fixed-header
      hide-default-footer
      :headers="headers"
      :items="widget"
      :items-per-page="-1"
    >
      <!-- custom table header columns -->
      <template #header.leftHours="{ header }">
        <span v-html="header.text"></span>
      </template>

      <template #header.totalHours="{ header }">
        <span v-html="header.text"></span>
      </template>

      <!-- custom table body columns -->
      <template #item.leftHours="{ item }">
        <span :class="!isCorrectWidgetSum || !item.isCorrect ? 'error--text' : 'success--text'">
          {{ item.leftHours }}
        </span>
      </template>

      <template #item.totalHours="{ item }">
        <span :class="!isCorrectWidgetSum || !item.isCorrect ? 'error--text' : 'success--text'">
          {{ item.totalHours }}
        </span>
      </template>

      <template #item.readyHours="{ item }">
        <span :class="!isCorrectWidgetSum || !item.isCorrect ? 'error--text' : 'success--text'">
          {{ item.readyHours }}
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'ScheduleWidget',

  props: {
    widget: Array,
    isCorrectWidgetSum: Boolean
  },

  data: () => ({
    headers: [
      { text: 'Тип занятия', value: 'name', sortable: false, align: 'left' },
      { text: 'Осталось<br>распределить', value: 'leftHours', sortable: false, align: 'center', width: 90 },
      { text: 'Всего<br>часов', value: 'totalHours', sortable: false, align: 'center', width: 40 },
      { text: 'Распределено', value: 'readyHours', sortable: false, align: 'center', width: 90 }
    ]
  })
}
</script>

<style scoped>
.base-table >>> .v-data-table-header th {
  padding: 0 16px 0 0 !important;
  font-size: 14px !important;
}

.base-table >>> .v-data-table-header th:last-child {
  padding-right: 0 !important;
}

.base-table >>> td {
  font-size: 14px !important;
  font-weight: 500 !important;
  height: 26px !important;
  padding-left: 0 !important;
  padding-right: 16px !important;
}

.base-table >>> td:first-child {
  padding-left: 0 !important;
}

.base-table >>> td:last-child {
  padding-right: 0 !important;
}
</style>