<template>
  <v-menu offset-y v-model="showMenu">
    <template #activator="{ on }">
      <div
        v-on="!disabled ? on : ''"
        :class="{ 'cursor-help': disabled }"
        :title="disabled ? 'Для формирования отчетов необходимо создать и полностью заполнить фактическое расписание' : ''"
      >
        <v-btn
          :disabled="disabled"
          text
          color="accent"
          class="print-btn px-0"
          title="Печать"
          :ripple="false"
        >
          <v-icon left class="mr-0" size="20">mdi-printer-outline</v-icon>
          <span class="ml-2">Печать</span>
          <v-icon right class="ml-1">{{ showMenu ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </div>
    </template>

    <v-list>
      <v-list-item dense target="_blank" :to="reportApi.dpoSchedule(cycleId)">
        <v-list-item-title class="list-text">
          Печать фактического расписания
        </v-list-item-title>
      </v-list-item>

      <v-list-item dense target="_blank" :to="reportApi.dpoCuratorReport(cycleId)">
        <v-list-item-title class="list-text">
          Отчет куратора
        </v-list-item-title>
      </v-list-item>

      <v-list-item dense target="_blank" :to="reportApi.dpoTeacherWorkloadByCycle(cycleId)">
        <v-list-item-title class="list-text">
          Нагрузка преподавателей цикла
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { reportApi } from '@/api'
import { mapGetters } from 'vuex'

export default {
  name: "SheduleReportMenu",

  props: {
    disabled: Boolean
  },

  data: () => ({
    reportApi,
    showMenu: false
  }),

  computed: {
    ...mapGetters('cycle', ['cycleId'])
  }
}
</script>